import React, {useState} from "react"
import {graphql, Link} from "gatsby"
import Rotation from 'react-rotation'
import Img from "gatsby-image"
import imgPage from "../images/headers/Logos_Mex_Jet.svg"
import Layout from "../components/layout.en"
import SEO from "../components/seo"
import Header from "../components/header";
import style from "./singleJets.module.scss"
import Rango from "../images/marine/icon_velocidad.svg";
import Techo from "../images/icons/icon_techo.svg";
import Equipaje from "../images/icons/icon_equipaje.svg";
import Pasajeros from "../images/icons/icon_pasajeros.svg";
import Form from "./form.en";

export default ({data, pageContext: {slug}}) => {
    const post = data.markdownRemark;
    const imagesRotation = data.rotation.edges;
    const imagesCross = data.cross.edges;
    const imagesSide = data.side.edges;
    const imagesFront = data.front.edges;

    var [dist, setDist] = useState('tlc')

    var flagImg = 0;

    function changeDist(d) {
        setDist(d)
    }

    const extraInfo =
        [
            {name: 'Jorge Ramírez', mail: 'j.ramirez@ale.mx', phone: '55 4521 9646'},
        ];
    return (
        <Layout extraInfo={extraInfo}>

            <Header LogoPage={imgPage}/>
            <SEO title="Mexjet Aerolineas Ejecutivas"/>
            <div className={style.jetsCover}>
                <Img fluid={post.frontmatter.cover.childImageSharp.fluid}/>
            </div>

            <Form options={['MexJet: Fractional Aircraft/Jetcards (hrs)']}/>

            <div className={style.jetsInfo}>
                <h1>{post.frontmatter.title}</h1>
                <p>{post.frontmatter.description}</p>
            </div>

            <div className={style.jetsSpecs}>
                <h2>Specifications</h2>
                <div className={style.content}>
                    <div className={style.item}>
                        <div className={style.top}>
                            <p>Crew + Passengers</p>
                            <span> </span>
                        </div>
                        <div className={style.bottom}>
                            <Pasajeros/>
                            <p>{post.frontmatter.pasajeros}</p>
                        </div>
                    </div>
                    <div className={style.item}>
                        <div className={style.top}>
                            <p>Cargo Capacity</p>
                            <span> </span>
                        </div>
                        <div className={style.bottom}>
                            <Equipaje/>
                            <p>{post.frontmatter.equipaje}</p>
                        </div>
                    </div>
                    <div className={style.item}>
                        <div className={style.top}>
                            <p>Maximum Speed</p>
                            <span> </span>
                        </div>
                        <div className={style.bottom}>
                            <Rango/>
                            <p>{post.frontmatter.velocidad}</p>
                        </div>
                    </div>
                    <div className={style.item}>
                        <div className={style.top}>
                            <p>Ceiling</p>
                            <span> </span>
                        </div>
                        <div className={style.bottom}>
                            <Techo/>
                            <p>{post.frontmatter.techo}</p>
                        </div>
                    </div>
                </div>

            </div>
            {(post.frontmatter.slug !== '/mexjet/ach154/') &&
            <Rotation className={style.jets3D} cycle={true} reverse={true}>
                {imagesRotation.map((item) => {
                    flagImg++
                    return <Img key={flagImg} className={style.img} fluid={item.node.childImageSharp.fluid}/>
                })}
            </Rotation>
            }

            <div className={style.jetsCross}>
                {imagesCross.map((item) => {
                    return <img alt="cross" className={style.img} key={item.node.publicURL} src={item.node.publicURL}/>
                })}
            </div>

            <div className={style.jetsTitles}>
                <p className={style.big}>Side View
                    <span> </span>
                </p>
                <p className={style.big}>Front View
                    <span> </span>
                </p>
                <p className={style.small}>Views</p>
            </div>


            <div className={style.jetsSizes}>
                <div className={style.jetsSides}>
                    {imagesSide.map((item) => {
                    console.log(item)
                        return <img alt="cross" className={style.img} src={item.node.publicURL}/>
                    })}
                </div>
                <div className={style.jetsSides}>
                    {imagesFront.map((item) => {
                        return <img alt="cross" className={style.img} src={item.node.publicURL}/>
                    })}
                </div>
            </div>

            <div className={style.jetsCover}>
                {post.frontmatter.view360 &&
                <a target={'_blank'} className={style.top} href={post.frontmatter.view360} rel="noopener noreferrer">
                    360 View
                </a>
                }
                <Img style={{maxHeight: '350px'}} className={style.jetsControls} fluid={post.frontmatter.controls.childImageSharp.fluid}/>
            </div>


            <div className={style.jetsMap}>
                <h2>Range Map</h2>
                <div className={style.titles}>
                    <label>
                        <input onChange={() => changeDist('tlc')} value='tlc' type="radio" name='alcance'
                               checked={dist === 'tlc'}/>
                        <span> </span>TLC | Toluca
                    </label>
                    <label>
                        <input onChange={() => changeDist('mty')} value='mty' type="radio" name='alcance'
                               checked={dist === 'mty'}/>
                        <span> </span>MTY | Monterrey
                    </label>
                </div>
            </div>
            <div className={style.jetsMap}>
                <div className={style.map}>
                    <div
                        className={dist === 'tlc' ? `${style.active} ${style.tlc} ${style.item}` : `${style.tlc} ${style.item}`}>
                        <span> </span>
                        <span className={style.alcance}
                              style={{transform: `scale(${post.frontmatter.alcance})`}}> </span>
                        <p>Toluca</p>
                    </div>
                    <div
                        className={dist === 'mty' ? `${style.active} ${style.mty} ${style.item}` : `${style.mty} ${style.item}`}>
                        <span> </span>
                        <span className={style.alcance}
                              style={{transform: `scale(${post.frontmatter.alcance})`}}> </span>
                        <p>Monterrey</p>
                    </div>
                    <Img fluid={data.map.childImageSharp.fluid}/>
                </div>
            </div>

            <div className={style.jetsCover}>
                <Img style={{maxHeight: '550px'}} fluid={post.frontmatter.footer.childImageSharp.fluid}/>
                <Link to="/contact#form-contact">CONTACT A REPRESENTATIVE</Link>
            </div>

        </Layout>
    )
}
export const query = graphql`
query($slug: String!) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
        frontmatter {
            slug
            title
            pasajeros
            techo
            velocidad
            alcance
            description
            view360
            equipaje
            cover {
                childImageSharp {
                    fluid(maxWidth: 1980) {
                          ...GatsbyImageSharpFluid_withWebp
                    }
                   
                }
            }
            footer {
                childImageSharp {
                    fluid(maxWidth: 1980) {
                          ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            controls {
                childImageSharp {
                    fluid(maxWidth: 1980) {
                          ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
    rotation: allFile(filter: {absolutePath: {regex: $slug}, name: {regex: "/frame/"}}, sort: {fields: name, order: ASC}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    cross: allFile(filter: {absolutePath: {regex: $slug}, name: {regex: "/cross/"}}, sort: {fields: name, order: ASC}) {
      edges {
        node {
          publicURL
        }
      }
    }
    
    front: allFile(filter: {absolutePath: {regex: $slug}, name: {regex: "/front/"}}, sort: {fields: name, order: ASC}) {
      edges {
        node {
          publicURL
        }
      }
    }
    
    side: allFile(filter: {absolutePath: {regex: $slug}, name: {regex: "/side/"}}, sort: {fields: name, order: ASC}) {
      edges {
        node {
          publicURL
        }
      }
    }
    map: file(relativePath: {eq: "mexjet/mapa.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
   
    fotoTablero: file(relativePath: {eq: "mexjet/foto_galeria.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`
